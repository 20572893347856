import { useCallback, useEffect, useRef, useState } from 'react';

let lastScrollY = 0;
let deltaSum = 0;

export default function useAutoHideHeader({
  navHeight,
  speedModifier = 1,
}: {
  navHeight: number;
  speedModifier?: number;
  min?: number;
}) {
  const nav = useRef<HTMLElement>();
  const [isAtTop, setIsAtTop] = useState(true);

  const scrollHandler = useCallback(() => {
    const newScrollY = window.scrollY;
    const deltaY = lastScrollY - newScrollY;

    deltaSum += deltaY;

    const oT = Math.min(navHeight - 88, -deltaSum);

    if (nav.current) {
      nav.current.style.height = `${Math.min(navHeight, navHeight - oT)}px`;
    }
    setIsAtTop(newScrollY <= navHeight / speedModifier);

    lastScrollY = newScrollY;
  }, [nav, navHeight]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  return {
    isAtTop,
    nav,
  };
}
