import { Box, Flex, Stack, Text } from '@chakra-ui/react';

import CQCCert from '@/components/Certs/CQCCert';
import LegitScriptCert from '@/components/Certs/LegitScriptCert';
import Logo from '@/components/Icons/Logo';
import { Link } from '@/elements';

import SectionColumn from '../SectionColumn';
import SectionRow from '../SectionRow';

// const linkSections = [
//   {
//     title: 'We help with',
//     links: [
//       { label: 'Low Testosterone', url: '/articles/low-testosterone' },
//       { label: 'Erectile Dysfunction', url: '/articles/erectile-dysfunction' },
//       { label: 'Hair Loss', url: '/articles/hair-loss' },
//       {
//         label: 'Premature Ejaculation',
//         url: '/articles/premature-ejaculation',
//       },
//     ],
//   },
//   {
//     title: 'Learn',
//     links: [
//       { label: 'About us', url: '/about' },
//       { label: 'Knowledge hub', url: '/knowledge-hub' },
//       { label: 'Premium community', url: '/community' },
//       { label: 'FAQ', url: '/faq' },
//     ],
//   },
// ];

const Footer = () => {
  return (
    <Box as='footer'>
      <SectionRow>
        <SectionColumn
          borderBottom='1px solid rgba(215, 203, 195, 0.1)'
          borderTop='1px solid rgba(215, 203, 195, 0.1)'
          py={{
            base: 'lg',
            lg: 'xl',
          }}
        >
          <Flex
            alignItems='center'
            flexWrap='wrap'
            justifyContent='space-between'
          >
            <Box>
              <Logo color='white' height='56px' />
            </Box>

            <Stack
              alignItems='center'
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={{ xs: 'center', md: 'start' }}
              mt={{ xs: 'md', md: 0 }}
              spacing='lg'
              w={{ xs: '100%', md: 'auto' }}
            >
              <CQCCert />
              <LegitScriptCert />
            </Stack>

            {/* <Flex flexGrow={1} flexWrap='wrap' justifyContent='flex-start'> */}
            {/*  {linkSections.map((section) => ( */}
            {/*    <Box key={section.title} mb='xl' minWidth='300px'> */}
            {/*      <H4 fontSize='22px' mb='5'> */}
            {/*        {section.title} */}
            {/*      </H4> */}
            {/*      {section.links.map((link) => ( */}
            {/*        <Box key={link.url} mb='md'> */}
            {/*          <Link href={link.url}>{link.label}</Link> */}
            {/*        </Box> */}
            {/*      ))} */}
            {/*    </Box> */}
            {/*  ))} */}
            {/*  <Box minWidth='300px'> */}
            {/*    <H4 fontSize='22px' mb='lg'> */}
            {/*      Ask a doctor */}
            {/*    </H4> */}
            {/*  </Box> */}
            {/* </Flex> */}
          </Flex>
        </SectionColumn>
      </SectionRow>
      <SectionRow>
        <SectionColumn
          borderBottom='1px solid rgba(215, 203, 195, 0.1)'
          borderTop='1px solid rgba(215, 203, 195, 0.1)'
          pb='sm'
          pt={{
            base: 'lg',
            lg: 'xl',
          }}
          py={0}
        >
          <Flex
            color='rgba(255, 255, 255, 0.5)'
            flexWrap='wrap'
            fontSize='sm'
            justifyContent='space-between'
          >
            <Box mb='lg'>
              <Text maxW='450px' minWidth='280px'>
                Tedshealth is regulated by the Care Quality Commission, the
                independent regulator of all health and social care services in
                England
              </Text>
            </Box>
            <Flex flexWrap='wrap' justifyContent='flex-end'>
              <Box>
                <Link href='/legal/terms-and-conditions' mr='md'>
                  Terms of Service
                </Link>
              </Box>
              <Box>
                <Link href='/legal/privacy' mr='md'>
                  Privacy Policy
                </Link>
              </Box>
            </Flex>
          </Flex>
        </SectionColumn>
      </SectionRow>
    </Box>
  );
};

export default Footer;
