import React, { FC } from 'react';

import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  chakra,
  Button,
  BoxProps,
  useTheme,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';

import Logo from '@/components/Icons/Logo';
import MobileHeader from '@/components/Layout/Header/MobileHeader';
import { useAuth } from '@/context/AuthContext';
import { Link } from '@/elements';

import useAutoHideHeader from './useAutoHideHeader';

const HeaderContainer = chakra(Box, {
  baseStyle: {
    width: '100%',
    backgroundColor: 'brand.dark',
    top: 0,
    zIndex: 'overlay',
    display: 'flex',
    alignItems: 'center',
  },
});

const Header: FC<BoxProps & { height: number }> = ({ height, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isAtTop, nav } = useAutoHideHeader({
    navHeight: height,
  });

  const { isAuthenticated } = useAuth();

  return (
    <HeaderContainer
      ref={nav}
      backgroundColor={isAtTop ? 'transparent' : 'brand.dark'}
      height={`${height}px`}
      position='fixed'
      px={{
        base: 'md',
        sm: 'md',
        md: 'lg',
        lg: 'xl',
      }}
      transition='background-color 300ms'
      {...rest}
    >
      <Link
        css='svg { max-height: 60px }'
        height='100%'
        href='/'
        paddingY={{
          base: '16px',
          md: '24px',
        }}
      >
        <Logo color='white' height='100%' />
      </Link>

      <Box
        display={{
          base: 'none',
          md: 'initial',
        }}
        ml='auto'
      >
        {!isAuthenticated && (
          <Link color='white' data-cy='signInButton' href='/auth/signin' mx={8}>
            Sign In
          </Link>
        )}
        {!isAuthenticated && (
          <Button
            as={Link}
            colorScheme='white'
            data-cy='registerButton'
            href='/auth/register'
            mx={8}
            variant='outline'
          >
            Sign Up
          </Button>
        )}
        {isAuthenticated && (
          <Link color='white' href='/dashboard' mx={8}>
            Dashboard
          </Link>
        )}
        {isAuthenticated && (
          <Link color='white' data-cy='logout' href='/auth/signout' mx={8}>
            Log Out
          </Link>
        )}
      </Box>

      <IconButton
        _active={{ bg: 'none' }}
        _focus={{ bg: 'none' }}
        _hover={{ bg: 'none' }}
        aria-label='Open Menu'
        bg='none'
        display={{ base: 'flex', md: 'none' }}
        icon={<HamburgerIcon />}
        ml='auto'
        onClick={onOpen}
        size='lg'
      />

      <MobileHeader isOpen={isOpen} onClose={onClose} />
    </HeaderContainer>
  );
};

export default () => {
  const theme = useTheme();

  return (
    <>
      <Header
        display={{ base: 'none', md: 'flex' }}
        height={theme.header.height}
      />
      <Header
        display={{ base: 'flex', md: 'none' }}
        height={theme.header.mobileHeight}
      />
    </>
  );
};
