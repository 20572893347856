import Script from 'next/script';

import { Link, Image } from '@/elements';

const LegitScriptCert = () => {
  return (
    <>
      <Script id='legit-script'>
        {`
          function loadCert(){ 
            var link = document.getElementById('legitscript-link');
            var url = location.protocol + "//static.legitscript.com";
            link.href = 'https://www.legitscript.com';
            link.target = "_blank"
            var img =  document.getElementById('legitscript-emblem');
            img.width = 140;
            img.height = 120;
            img.src = url + "/seals/11887306.png"
          };  
          loadCert();
        `}
      </Script>
      <Link href='placeholder' id='legitscript-link'>
        <Image alt='LegitScript approved' id='legitscript-emblem' />
      </Link>
    </>
  );
};

export default LegitScriptCert;
