import { BoxProps } from '@chakra-ui/react';

import SectionColumn from './SectionColumn';
import SectionContent from './SectionContent';
import SectionRow from './SectionRow';

export default function Section({
  contentProps,
  ...props
}: { contentProps?: BoxProps } & BoxProps) {
  const { children, ...rest } = props;
  return (
    <SectionRow {...rest}>
      <SectionColumn>
        <SectionContent {...contentProps}>{children}</SectionContent>
      </SectionColumn>
    </SectionRow>
  );
}
