import React, { FC } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  VStack,
} from '@chakra-ui/react';

import { useAuth } from '@/context/AuthContext';
import { Link } from '@/elements';

const MobileHeader: FC<{ onClose: () => void; isOpen: boolean }> = ({
  onClose,
  isOpen,
}) => {
  const { isAuthenticated } = useAuth();

  return (
    <Modal
      autoFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      size='full'
    >
      <ModalContent bg='brand.dark'>
        <ModalHeader>
          <ModalCloseButton color='white.100' right='24px' top='24px' />
        </ModalHeader>
        <ModalBody>
          <VStack spacing='lg'>
            {!isAuthenticated && (
              <Link color='white' fontSize='md' href='/auth/signin'>
                Sign In
              </Link>
            )}
            {!isAuthenticated && (
              <Button
                as={Link}
                colorScheme='white'
                fontSize='md'
                href='/auth/register'
                variant='outline'
              >
                Sign Up
              </Button>
            )}
            {isAuthenticated && (
              <Link color='white' fontSize='md' href='/dashboard'>
                Dashboard
              </Link>
            )}
            {isAuthenticated && (
              <Link color='white' fontSize='md' href='/auth/signout'>
                Log Out
              </Link>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileHeader;
