import { Image, Link } from '@/elements';

const CQCCert = () => {
  return (
    <Link href='https://www.cqc.org.uk/provider/1-10511753645' isExternal>
      <Image alt='cqc logo' h='70px' src='/images/cqc-logo.png' w='140px' />
    </Link>
  );
};

export default CQCCert;
